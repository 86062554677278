<template>
  <div class="organization-detail-page page-layout">
    <!--actionbar pour le button mise a jour-->
    <PageHeader
      title="Cabinets"
      :has-search="false"
      :has-back="true"
      :has-actions="!error && !isLoading"
      @back="goBack"
    >
      <md-button
        class="md-primary md-raised"
        @click="$router.push('/admin/organizations/update/' + organization.id)"
      >
        Editer
      </md-button>
    </PageHeader>

    <LoadingCard v-if="isLoading" />

    <md-card v-if="!isLoading && error" class="page-list">
      <md-card-content>
        Cabinet non trouvable
        <md-empty-state />
      </md-card-content>
    </md-card>

    <!--layout pour tous les details-->
    <md-card
      v-else
      class="meep-form md-layout-item md-size-100 md-small-size-100"
    >
      <!--le nom d'organization -->
      <md-card-content>
        <md-list>
          <md-subheader>
            Nom de l'organization
          </md-subheader>

          <md-list-item>
            <div class="md-list-item-text">
              {{ organization.name }}
            </div>
          </md-list-item>

          <md-subheader>
            Logo
          </md-subheader>

          <md-list-item>
            <div
              v-show="logoPreview !== null"
              id="logo-preview"
              style="width: 220px; height: 128px;"
              class="logo-preview"
              v-html="logoPreview"
            ></div>
          </md-list-item>

          <md-subheader>
            Logo Minified
          </md-subheader>

          <md-list-item>
            <div
              v-show="logoMinifiedPreview !== null"
              id="logo-preview"
              style="width: 220px; height: 128px;"
              class="logo-preview"
              v-html="logoMinifiedPreview"
            ></div>
          </md-list-item>

          <template v-if="organization.emailBanner">
            <md-subheader>
              Bandeau email
            </md-subheader>

            <md-list-item>
              <img :src="organization.emailBanner" alt="" />
            </md-list-item>
          </template>
          <template v-if="organization.footerBanner">
            <md-subheader>
              Footer bandeau
            </md-subheader>

            <md-list-item>
              <img :src="organization.footerBanner" alt="" />
            </md-list-item>
          </template>

          <md-list-item>
            <md-checkbox v-model="organization.has_cloud" disabled
              >Cloud</md-checkbox
            >
          </md-list-item>

          <md-list-item>
            <md-checkbox v-model="organization.hasCloudSync" disabled
              >Cloud Synchro</md-checkbox
            >
          </md-list-item>

          <md-list-item>
            <md-checkbox v-model="organization.has_email_synchro" disabled
              >Email Synchro</md-checkbox
            >
          </md-list-item>

          <md-list-item>
            <md-checkbox
              v-model="organization.has_lock_folder_ability"
              disabled
              >{{ $t("organization.has_lock_folder_ability") }}</md-checkbox
            >
          </md-list-item>

          <template v-if="organization.has_cloud">
            <md-subheader>Identifiant de compte Cloud</md-subheader>

            <md-list-item>
              <div class="md-list-item-text">
                {{ organization.owncloud_id }}
              </div>
            </md-list-item>

            <md-subheader>Mot de passe Cloud</md-subheader>

            <md-list-item>
              <div class="md-list-item-text">
                {{ organization.owncloud_password }}
              </div>
            </md-list-item>

            <md-subheader>URL du Cloud</md-subheader>

            <md-list-item>
              <div class="md-list-item-text">
                {{ organization.owncloud_endpoint }}
              </div>
            </md-list-item>

            <md-subheader>Racine du Cloud</md-subheader>

            <md-list-item>
              <div class="md-list-item-text">
                {{ organization.owncloud_root }}
              </div>
            </md-list-item>
          </template>

          <md-list-item v-else>
            <div class="md-list-item-text">
              Ce cabinet n'a pas le cloud
            </div>
          </md-list-item>

          <md-subheader>
            URL d'espace client
          </md-subheader>

          <md-list-item>
            <div class="md-list-item-text">
              {{ organization.homepageUrl }}
            </div>
          </md-list-item>

          <md-subheader>
            URL de l'application Android
          </md-subheader>

          <md-list-item>
            <div class="md-list-item-text">
              {{ organization.androidUrl }}
            </div>
          </md-list-item>

          <md-subheader>
            URL de l'application iOs
          </md-subheader>

          <md-list-item>
            <div class="md-list-item-text">
              {{ organization.iOsUrl }}
            </div>
          </md-list-item>

          <md-card-header>Siren</md-card-header>

          <md-list-item>
            <div class="md-list-item-text">
              {{ organization.siren }}
            </div>
          </md-list-item>

          <md-card-header>Addresse</md-card-header>

          <!-- l'addresse -->
          <md-list-item>
            <div class="md-list-item-text">
              {{ organization.adresse }}
            </div>
          </md-list-item>

          <md-card-header>CodePostal</md-card-header>

          <!--le codepostale-->
          <md-list-item>
            <div class="md-list-item-text">
              {{ organization.codepostal }}
            </div>
          </md-list-item>

          <md-card-header>Télephone</md-card-header>

          <!--le telephone -->
          <md-list-item>
            <div class="md-list-item-text">
              {{ organization.tel }}
            </div>
          </md-list-item>

          <md-card-header>Ville</md-card-header>

          <!--la ville-->
          <md-list-item>
            <div class="md-list-item-text">
              {{ organization.ville }}
            </div>
          </md-list-item>

          <md-card-header>Url</md-card-header>

          <!-- l'url-->
          <md-list-item>
            <div class="md-list-item-text">
              {{ organization.url }}
            </div>
          </md-list-item>

          <md-card-header>Thème</md-card-header>

          <!-- le thème-->
          <md-list-item>
            <div class="md-list-item-text">
              {{ organization.color_name }}
            </div>
          </md-list-item>

          <md-card-header>{{
            $t("organization.contact_email")
          }}</md-card-header>

          <!-- le thème-->
          <md-list-item>
            <div class="md-list-item-text">
              {{ organization.contact_email }}
            </div>
          </md-list-item>
        </md-list>
      </md-card-content>
    </md-card>
  </div>
</template>
<script>
import { themeOptions } from "../../../themes";
import organizations from "../../../model/organizations";
import PageHeader from "@/components/PageHeader";
import LoadingCard from "@/components/LoadingCard";

export default {
  name: "ViewOrganization",

  components: { PageHeader, LoadingCard },

  data() {
    return {
      organization: {},
      disabled: true,
      logoPreview: null,
      logoMinifiedPreview: null,
      isLoading: false,
      error: false,
    };
  },

  watch: {
    $route: "loadOrganization",
  },

  async mounted() {
    await this.loadOrganization();
  },

  methods: {
    goBack() {
      window.history.back();
    },

    async loadOrganization() {
      this.isLoading = true;
      try {
        const organization = await organizations.get(this.$route.params.id);

        organization.color_name = themeOptions.find(
          o => o.id == organization.color_scheme
        ).name;
        this.organization = organization;
        this.logoPreview = organization.logo;
        this.logoMinifiedPreview = organization.logoMinified;
      } catch (err) {
        console.log(err);
        this.$toasted.global.AppError({
          message: err.msg,
        });

        this.error = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<!-- CSS pour layout de page-->
<style lang="scss" scoped>
.organization-detail-page {
  .md-card {
    margin-top: 32px;
    margin-bottom: 32px;
    margin-left: auto;
    margin-right: auto;
  }
}

.logo-preview {
  width: 220px;
  height: 128px;
}
</style>
